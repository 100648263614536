import { SectionsKey } from '~/constants/enum';

const PageStruct = {
    id: 'search-result',
    useLayout: true,
    children: [
        { id: SectionsKey.ProductList, order: 1, dataKey: SectionsKey.ProductList },
        { id: SectionsKey.RecentlyView, order: 2, dataKey: SectionsKey.RecentlyView }
    ]
};

export default PageStruct;
