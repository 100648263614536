import { SectionsKey } from '~/constants/enum';

const PageStruct = {
    id: 'product-detail-two',
    useLayout: true,
    children: [
        {
            id: SectionsKey.ProductDetailTwo,
            order: 1,
            dataKey: SectionsKey.ProductDetailTwo
        },
        {
            id: SectionsKey.Related,
            order: 2,
            dataKey: SectionsKey.Related
        },
        {
            id: SectionsKey.ProductTags,
            order: 3,
            dataKey: SectionsKey.ProductTags
        }
    ]
};

export default PageStruct;
