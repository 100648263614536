import { SectionsKey } from "~/constants/enum";

const PageStruct = {
  id: "product-detail-similar",
  useLayout: true,
  children: [
    {
      id: SectionsKey.ProductDetailSimilar,
      order: 1,
      dataKey: SectionsKey.ProductDetailSimilar,
    },
    {
      id: SectionsKey.SimilarProduct,
      order: 3,
      dataKey: SectionsKey.SimilarProduct,
    },
    {
      id: SectionsKey.RecentlyView,
      order: 3,
    },
  ],
};

export default PageStruct;
