import Head from "next/head";
import { useRouter } from "next/router";
import { FC, ReactNode } from "react";
import { useRecoilValue } from "recoil";

interface SEOTagsProps {
  title?: string;
  metaTitle?: string;
  description?: string;
  keywords?: string;
  author?: string;
  url?: string;
  image?: string;
  twitterHandle?: string;
  type?: string;
  children?: ReactNode;
}

const SEOTags: FC<SEOTagsProps> = ({
  title = "",
  metaTitle = "",
  description = process.env.NEXT_PUBLIC_SEO_DEFAULT_DESCRIPTION,
  keywords = process.env.NEXT_PUBLIC_SEO_DEFAULT_KEYWORDS,
  author = process.env.NEXT_PUBLIC_SEO_DEFAULT_AUTHOR,
  url = process.env.NEXT_PUBLIC_SEO_DEFAULT_URL,
  image = process.env.NEXT_PUBLIC_SEO_DEFAULT_IMAGE,
  twitterHandle = process.env.NEXT_PUBLIC_SEO_DEFAULT_TWWITER_HANDLE,
  type = process.env.NEXT_PUBLIC_SEO_DEFAULT_TYPE,
  children,
}) => {
  const router = useRouter();

  const newPathName = url ? url : router.asPath;

  // og
  const OG_URL = !!process.env.NEXT_PUBLIC_URL
    ? `${process.env.NEXT_PUBLIC_URL}${newPathName}`
    : "";
  const newTitle = [
    title,
    process.env.NEXT_PUBLIC_SEO_DEFAULT_BRAND + " Official Website",
    // process.env.NEXT_PUBLIC_SEO_DEFAULT_TITLE,
  ]
    .filter((i) => !!i)
    .join(" | ");
  return (
    <Head>
      <title>{!title && !!metaTitle ? metaTitle : newTitle}</title>
      <meta name="title" content={metaTitle || title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta property="og:title" content={metaTitle || title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={OG_URL} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content={type} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterHandle} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {children}
    </Head>
  );
};

export default SEOTags;
