import { SectionsKey } from "~/constants/enum";

const PageStruct = {
  id: "category-detail",
  useLayout: true,
  children: [
    { id: SectionsKey.ProductList, order: 1, dataKey: SectionsKey.ProductList },
  ],
};

export default PageStruct;
