import { SectionsKey } from '~/constants/enum';

const PageStruct = {
    id: 'collections',
    useLayout: true,
    children: [
        { id: 'breadcrumb', order: 1 },
        { id: 'collection-about', order: 2, dataKey: SectionsKey.CollectionsAbout },
        { id: 'collections', order: 3, dataKey: SectionsKey.Collections },
        { id: 'collection-recommend', order: 4, dataKey: SectionsKey.CollectionsRecommend }
    ]
};

export default PageStruct;
