import { SectionsKey } from '~/constants/enum';

const PageStruct = {
    id: 'blog-list',
    useLayout: true,
    children: [
        // { id: "blog-slider", order: 1 },
        { id: SectionsKey.BlogList, order: 2, dataKey: SectionsKey.BlogList }
    ]
};

export default PageStruct;
