import { SectionsKey } from "~/constants/enum";

const PageStruct = {
  id: "collection-detail",
  useLayout: true,
  children: [
    { id: SectionsKey.ProductList, order: 1, dataKey: SectionsKey.ProductList },
    {
      id: SectionsKey.CollectionsContent,
      order: 2,
      dataKey: SectionsKey.CollectionsContent,
    },
    { id: SectionsKey.TabReviews, order: 3 },
    { id: SectionsKey.RecentlyView, order: 4 },
  ],
};

export default PageStruct;
