import { SectionsKey } from '~/constants/enum';
import { ProductDetailDataKey } from '~/pages/product/[slug]';

const PageStruct = {
    id: 'product-detail',
    useLayout: true,
    children: [
        { id: SectionsKey.Breadcrumb, order: 1 },
        { id: SectionsKey.ProductDetail, order: 2 },
        { id: SectionsKey.ProductDetailTabs, order: 3 }
        // {
        //     id: SectionsKey.ProductRelated,
        //     order: 5,
        //     dataKey: ProductDetailDataKey.CustomersWhoViewedThisItemAlsoViewed
        // },
        // {
        //     id: SectionsKey.ProductRelated,
        //     order: 6,
        //     dataKey: ProductDetailDataKey.CustomersWhoBoughtThisItemAlsoBought
        // },
        // {
        //     id: SectionsKey.ProductRelated,
        //     order: 7,
        //     dataKey: ProductDetailDataKey.ExclusiveItemsFromOurBrands
        // },
        // {
        //     id: SectionsKey.ProductRelated,
        //     order: 8,
        //     dataKey: ProductDetailDataKey.InspiredByYourBrowsingHistory
        // },
        // {
        //     id: SectionsKey.ProductRelated,
        //     order: 9,
        //     dataKey: ProductDetailDataKey.ProductsRelatedToThisItem
        // },
        // {
        //   id: SectionsKey.ProductSection,
        //   order: 6,
        //   dataKey: "new_arrivals",
        // },
        // {
        //   id: "product-detail-best-seller-banner",
        //   order: 7,
        // },
        // {
        //   id: "product-group",
        //   order: 8,
        // },
    ]
};

export default PageStruct;
