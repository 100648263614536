import React, { useEffect, useState } from "react";
import styles from "./LoadingPage.module.scss";
import classNames from "classnames";
import Icon from "../Icon";
import LoadingIcon from "~/assets/svgs/status.svg";

type Props = {
  text?: string;
  timeOut?: number;
  onOverTime?: () => void;
};

const LoadingPage = ({
  text = "Loading...",
  timeOut = 20000,
  onOverTime,
}: Props) => {
  return (
    <div className={styles.loadingPage}>
      <div className={classNames(styles.loadingContent)}>
        {text}
        <br />
        <span className="inline-flex">
          <LoadingIcon
            alt="Star"
            width={32}
            height={32}
            color="#fff"
            className="animate-spin "
          />
        </span>
      </div>
    </div>
  );
};

export default LoadingPage;
