import { SectionsKey } from "~/constants/enum";

const PageStruct = {
  id: "history",
  useLayout: true,
  children: [
    { id: "breadcrumb", order: 1 },
    { id: SectionsKey.History, order: 3, dataKey: SectionsKey.History },
  ],
};

export default PageStruct;
