import { SectionsKey } from '~/constants/enum';

const PageStruct = {
    id: 'cart',
    useLayout: true,
    children: [
        { id: SectionsKey.Cart, order: 1 },
        { id: SectionsKey.Related, order: 2, dataKey: SectionsKey.Related }
    ]
};

export default PageStruct;
