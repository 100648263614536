import { SectionsKey } from '~/constants/enum';

const PageStruct = {
    id: 'blog-list',
    useLayout: true,
    children: [
        { id: SectionsKey.BlogDetail, order: 1, dataKey: SectionsKey.BlogDetail },
        { id: SectionsKey.RelatedBlogs, order: 2, dataKey: SectionsKey.RelatedBlogs }
    ]
};

export default PageStruct;
