import Head from "next/head";
import { getUrlByTheme } from "~/utils/theme";

type Props = {
  config: any;
};

const HomeSchema = ({ config }: Props) => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: config }}
      >
        {}
      </script>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            url: process.env.NEXT_PUBLIC_URL,
            name: process.env.NEXT_PUBLIC_SEO_DEFAULT_BRAND,
            description: process.env.NEXT_PUBLIC_SEO_DEFAULT_DESCRIPTION,
            potentialAction: {
              "@type": "SearchAction",
              target: {
                "@type": "EntryPoint",
                urlTemplate: `${process.env.NEXT_PUBLIC_URL}/search?keyword={search_term_string}&utm_campaign=action_schema_markup`,
              },
              "query-input": "required name=search_term_string",
            },
          }),
        }}
      ></script>
    </Head>
  );
};

export default HomeSchema;
